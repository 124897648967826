export const environment = {
  production: true,
  config: {
    feedRoot: 'https://na.api.bo.ticket.run.airweb.fr/feed/',
    tapRoot: 'https://production-api-kcvqfuvlla-ew.a.run.app/',
    abtRoot: 'https://api.validators.ticket.airweb.fr',
    exportRoot: 'https://stable---na-export-ack5cv4rnq-nn.a.run.app/',
    mapApiKey: 'AIzaSyBBqcl8KrnY5PJetpLkmNT6SwxVdZwmDtQ',
    defaultCoords: '46.813878,-71.207981',
    loopedInApiBaseUrl:
      'https://northamerica-northeast2-ticket-cms.cloudfunctions.net/na-production-looped-in',
    loopedInWorkspaceId: '626a4cf7545a45002a3c5f02',
    statsRoot: 'https://stats.staging.airweb.fr',
    assetsRoot: 'https://na.assets.ticket.airweb.fr/',
    configURL: 'https://storage.googleapis.com/airweb-ticket-public-europe/back-office/config.json',
    clarityCode: 'd0s2llzfwm',

    adminlabsURL:
      'https://northamerica-northeast2-ticket-cms.cloudfunctions.net/na-production-admin-labs',
    adminlabsBackOfficeComponentIds: [
      'b59f0945-6f5c-11ee-ac0f-000c29d658bc',
      'c0ffae35-6f5c-11ee-ac0f-000c29d658bc',
      'cd9aa59c-6f5c-11ee-ac0f-000c29d658bc',
      '4317a607-6f5d-11ee-ac0f-000c29d658bc',
    ],
    airwebStatusUrl: 'https://na.status.airweb.fr/',
    newAuthenticationUrl: 'https://na.connect.airwebpass.com/auth',
    maintenanceUrlList: [],
  },
};
